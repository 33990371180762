import AjaxSwitch from "./fields/show/AjaxSwitch"

export default class IndexTemplate{
    constructor() {
        this.ajaxSwitch = null;
    }

    onPageChanged(){
        if(this.ajaxSwitch !== undefined){
            this.setAjaxSwitch();
        }
    }

    setAjaxSwitch() {
        this.ajaxSwitch = new AjaxSwitch();
    }

    onDestroy(){}
}