export default class AjaxSwitch {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        let self = this;
        $(".js-ajax-switch").unbind("change").change(function (){
            self.updateField(this);
        })
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    updateField(item) {
        let field = $(item).data("field");
        let model = $(item).data("model");
        let controller = $(item).data("controller");
        let id = $(item).data("id");
        let field_value = {};
        field_value[field] = $(item).is(":checked");
        let data = {};
        data[model] = field_value;
console.log(data);
        let ajax_data = {
            url: "/"+controller+"/"+id+"/ajax_update",
            data: data,
            method: "put",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success:function(data){
                application.successNotification(data.message)
            },
            error: function (err){
                application.errorNotification(err)
            }
        }

        $.ajax(ajax_data)
    }
}