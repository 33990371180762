export default class TemplateValidation{

    constructor(validationRules, form_element = '#crud_form') {
        this.validationRules = validationRules;
        this.form_element = form_element;
        this.validation = null;

        this.addCustomValidations();
        this.initValidation();
    }

    onDestroy(){
        if(this.validation !== undefined && this.validation !== null){
            this.validation.destroy();
        }
    }

    addCustomValidations() {

        // Validate french phone number: 10 chars type numbers ignoring spaces
        $.validator.addMethod("phone_number_fr", function( value, element ) {
            let val = value.replace(/ /g, '');
            return val === "" || val.match(/[0-9]/g) && value.replace(/ /g, '').length === 10 && (value.charAt(0) === "0");
        }, I18n.t('common.js_validation.phone_number_fr'));

        // Validate passwords: 8 to 70 chars containing at least 1 majus, 1 minus and 1 number
        $.validator.addMethod("password_validation", function( value, element ) {
            return value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/g) && value.length >= 8;
        }, I18n.t('common.js_validation.password_validation'));

        // Validate correct email: {some_chars}@{some_chars}.{2 to 4 chars}
        $.validator.addMethod("email_validation", function( value, element ) {
            return  value.match(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/);
        }, I18n.t('common.js_validation.email_validation'));
    }

    initValidation() {
        let options = {
            rules: this.validationRules,
            errorClass: "text-danger",
            errorElement: "span",
            highlight: this.highlightError,
            unhighlight: this.unhighlight,
            errorPlacement: (error, element) => {
                element.parent().append(error);
            }
        }

        if(I18n.locale !== 'en'){
            options.messages = require('jquery-validation/dist/localization/messages_'+I18n.locale)
        }
        this.validation = $(this.form_element).validate(options)
    }

    highlightError(element, errorClass){
        let element_id = $(element).addClass('border-danger').attr('id');
        $('label[for='+ element_id +']').addClass('text-danger');
    }

    unhighlight(element, errorClass){
        let element_id = $(element).removeClass('border-danger').attr('id');
        $('label[for='+ element_id +']').removeClass('text-danger');
    }
}